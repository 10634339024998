.navbar_section {
  width: 100%;
  top: 0;
  z-index: 10;
  height: 90px;
}

.container_navbar {
  padding-left: 110px;
  padding-right: 80px;
}

.container_navbar nav {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content_logo {
  width: 30%;
  display: flex;
  align-items: center;
}
.content_logo img {
  width: 65px;
  animation: rotation 2s infinite;
  cursor: pointer;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container_navbar nav ul {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.container_navbar nav ul li {
  list-style: none;
  text-decoration: none;
}
.container_navbar nav ul li .link {
  text-decoration: none;
  /* color: white; */
  text-transform: uppercase;
}

.container_navbar nav ul li:hover::before {
  width: 50px;
}

/* dropdown menu */

a.button {
  text-transform: uppercase;
  color: white;
  /* Style */
  border: none;
  cursor: pointer;
}

/* Dropdown styles */
.dropdown {
  position: relative;
  padding: 0;
  border: none;
}

.dropdown summary {
  list-style: none;
  list-style-type: none;
  /* font-weight: 600; */
}

.dropdown > summary::-webkit-details-marker {
  display: none;
}

.dropdown summary:focus {
  outline: none;
}

.dropdown summary:focus {
  outline: none;
}

.dropdown .ul {
  position: absolute;
  margin: 20px 0 0 0;
  width: 350px;
  height: 300px;
  padding: 0;
  z-index: 2;
  background: white;
  display: flex;
  flex-direction: column;
  list-style: none;
  transition: width 0.5s;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.dropdown .ul li .drop_link {
  text-decoration: none;
  color: var(--text-color);
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.dropdown .ul li .drop_link:hover {
  background-color: var(--text-color);
  color: white;
}

/* end dropdown menu */

#hamburgerEffect {
  display: none;
}
#hamburgerEffect:checked ~ #meny {
  transform: translateX(0rem);
}

#burger {
  position: absolute;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  right: 2rem;
  top: 0.4rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;
}

#burger > div {
  height: 2px;
  background-color: white;
  transition: 0.5s;
  z-index: 999;
}

#hamburgerEffect:checked ~ #burger > div {
  background-color: #fff;
}

#hamburgerEffect:checked ~ #burger > div:nth-child(1) {
  transform: translateY(15px) rotate(45deg);
}
#hamburgerEffect:checked ~ #burger > div:nth-child(2) {
  opacity: 0;
}
#hamburgerEffect:checked ~ #burger > div:nth-child(3) {
  transform: translateY(-15px) rotate(-45deg);
}

.drop_button {
  display: none;
}
/* @media screen and (max-width: 1023px) {
  .container_navbar {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 30px;
    height: 100px;
  }

  .container_navbar nav {
    width: 75%;
  }

  .content_logo {
    width: 25%;
  }
  .container_navbar nav ul {
    justify-content: space-between;
  }
} */
@media screen and (max-width: 960px) {
  .container_navbar {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 70px;
  }
  .container_navbar .menu_list {
    background-color: var(--text-color);
    height: 100vh;
    width: 70%;
    position: absolute;
    transition: width 0.5s ease-in-out;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    margin-right: 0px;
  }

  .container_navbar .menu_list ul {
    display: flex;
    width: 160px;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    left: 0;
    padding-top: 10px;
    justify-content: start;
    z-index: 2;
  }
  .container_navbar .menu_list ul li {
    margin-top: 50px;
  }
  .container_navbar .menu_list ul li .link {
    color: white;
  }
  .drop_button {
    display: block;
    z-index: 5;
  }
  a.button {
    color: white;
  }
  .content_logo img {
    width: 35px;
  }
}

@media screen and (max-width: 760px) {
  .container_navbar {
    height: 80px;
  }
}

@media screen and (max-width: 560px) {
  .container_navbar {
    height: 80px;
  }
  .container_navbar .menu_list1 {
    display: none;
  }
  .container_navbar .menu_list ul {
    width: 120px;
  }
  .container_navbar .menu_list ul {
    margin-top: 5px;
  }
  .container_navbar .menu_list ul li .link {
    font-size: 13px;
  }
  a.button {
    font-size: 13px;
  }
}

@media screen and (max-width: 320px) {
  .container_navbar {
    height: 60px;
  }
  .container_navbar .menu_list {
    width: 150px;
  }
  .container_navbar .menu_list ul {
    width: 90px;
  }
  .container_navbar .menu_list ul {
    margin-top: 5px;
  }
  .container_navbar .menu_list ul li .link {
    font-size: 10px;
  }
  a.button {
    font-size: 10px;
  }
  #burger {
    margin-top: 10px;
  }
}
