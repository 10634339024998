/* .service_card1 {
  position: relative;
  cursor: pointer;
  height: 150px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: transform 0.2s ease-in-out;
  border-radius: 10px;
}
.service_card {
  border-radius: 10px;
  position: absolute;
  background-color: white;
  margin-top: 5px;
  width: 100%;
  cursor: pointer;
  height: 150px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: transform 0.2s ease-in-out;
}
.service_card:hover {
  transform: scale(1.05);
  border-radius: 10px;
  transform: translateY(5px);
}

.service_card .service_card_image {
  width: 100%;
  height: 15%;
}
.service_card .service_card_text {
  width: 100%;
  height: 85%;
}

.netcard {
  width: 30px;
  height: 30px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: darkslategray;
  border-radius: 5px;
}
.netcard:hover {
  background-color: goldenrod;
}
 */

.netcard {
  width: 30px;
  height: 30px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: darkslategray;
  border-radius: 5px;
}
.netcard:hover {
  background-color: goldenrod;
}

.service__card {
  /* width: 380px; */
  height: 280px;
  border-radius: 5px;
  position: relative;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  transition: transform 0.5s;
  cursor: pointer;
}

.service__card__content {
  position: absolute;
  border-bottom: 2px solid var(--bg_black);
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.service__card:hover {
  transform: translateY(-10px);
  color: white;
}
.service__card:hover .service__card__content {
  background-color: green;
  opacity: 0.5;
  animation: couleur 0.5s;
}
@keyframes couleur {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}
.services__cards__text {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.service__card_rounded__image {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service__card_rounded__image .rounded__image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service__card:hover .rounded__image {
  animation: rotation 0.5s linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.service__card_content__text {
  padding-top: 15px;
  height: 60%;
  text-align: center;
}

/* .service__card_content__text h1{
font-size: 1.7em;
} */

/* .service__card_content__text p{

} */
.service__card_content__text a {
  text-decoration: none;
}
/* .service__card:hover a{
  color: white;
} */

@media screen and (max-width: 960px) {
  /* .service__card{
    margin: auto;
  }
   */
}

@media screen and (max-width: 720px) {
  /* .service__card{
    margin: auto;
  
  } */
}
@media screen and (max-width: 520px) {
  /* .service__card{
      width: 250px;
      height:250px;
  } */
  .service__card_rounded__image {
    height: 30%;
  }

  .service__card_content__text {
    height: 70%;
    text-align: center;
  }

  .service__card_content__text h1 {
    font-size: 1.2em;
  }

  .service__card_content__text p {
    font-size: 0.7em;
  }
  .service__card_content__text a {
    text-decoration: none;
    color: var(--bg_black);
    font-weight: bold;
  }

  .service__card_rounded__image .rounded__image {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 320px) {
  .service__card {
    width: 230px;
    height: 270px;
  }
}
