.container_footer{
    height: 600px;
    background-color:black;
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.container_footer .top {
    height: 75%;
    width: 100%;
}
.container_footer .bottom{
    width: 100%;
    height: 25%;
}

@media screen and (max-width:1022px) {
    .container_footer{
        padding-left: 50px;
        padding-right: 70px;
        
    }
    .card_container{
        grid-template-columns: repeat(3, 1fr);
     }
}
@media screen and (max-width:960px) {
    .container_footer{
        padding-left: 30px;
        padding-right: 50px;
        height: 100vh;
    }
    .container_footer .top {
        flex-direction: column;
        height: 80%;
    }
    .container_footer .bottom{
        height: 20%;
    }
}
@media screen and (max-width:560px) {
    .service{
        padding-left: 30px;
        padding-right: 30px;
        
    }
    .card_container{
        grid-template-columns: repeat(1, 1fr);
     }
}