@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100%;
}

:root {
  --text-color: green;
  --bg-gray: #f8f9fc;
  --button-background: dodgerblue;
  --button-color: white;

  --dropdown-highlight: dodgerblue;
  --dropdown-width: 160px;
  --dropdown-background: white;
  --dropdown-color: black;
}

.container_fluid {
  padding-left: 100px;
  padding-right: 100px;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media screen and (max-width: 960px) {
  .container_fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}
