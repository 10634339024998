.notfound{
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}
p{
    color: var(--text-color);
}
 .content_message{
    width: 400px;
    height: 400px;
    background-color: white;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    text-align: center;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}
 .content_message h3{
   font-size: 60px;
}
 .content_message a{
   font-size: 20px;
   text-decoration: none;
}
@media screen and (max-width:760px) {
    .content_message{
        width: 200px;
        height: 200px;
    }
    .content_message h3{
        font-size: 30px;
     }
     .content_message a{
        font-size: 15px;
     }
}