  .slider-one,
  .slider-two,
  .slider-three,
  .slider-four {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    animation: slider-one 12s linear infinite;
  
    .slider-one-image,
    .slider-two-image,
    .slider-three-image,
    .slider-four-image {
      width: 110%;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../../assets/images/real23.jpg");
  
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      animation: zoom-out 6s linear infinite;
  
      .slider-text {
        position: absolute;
        top: 30%;
        left: 5%;
        color: #ffffff;
        // width: 600px;
        text-transform: capitalize;
        animation: text-up 12s linear infinite;
        transform: translateY(100px);
        animation-delay: 0s;
      }
    }
  }
  
  .slider-two {
    animation: slider-two 12s linear infinite;
    .slider-two-image {
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../../assets/images/real1.jpeg") no-repeat;
      background-size: cover;
  
      .slider-text {
        animation-delay: 6s;
      }
    }
  }
  
  .slider-three {
    animation: slider-three 12s linear infinite;
  
    .slider-three-image {
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../../assets/images/real37.jpg") no-repeat;
      background-size: cover;
  
      .slider-text {
        animation-delay: 6s;
      }
    }
  }
  
  .slider-four {
    animation: slider-four 12s linear infinite;
  
    .slider-four-image {
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../../assets/images/real3.jpg") no-repeat;
      background-size: cover;
  
      .slider-text {
        animation-delay: 9s;
      }
    }
  }
  
  @keyframes text-up {
    10% {
      transform: translateY(0px);
    }
  
    100% {
      transform: translateY(0px);
    }
  }
  
  @keyframes zoom-out {
    100% {
      width: 100%;
    }
  }
  
  @keyframes slider-one {
    0% {
      visibility: visible;
    }
  
    25% {
      visibility: hidden;
    }
  
    50% {
      visibility: hidden;
    }
  
    75% {
      visibility: hidden;
    }
  
    100% {
      visibility: visible;
    }
  }
  
  @keyframes slider-two {
    0% {
      visibility: hidden;
    }
  
    25% {
      visibility: hidden;
    }
  
    50% {
      visibility: visible;
    }
  
    75% {
      visibility: hidden;
    }
  
    100% {
      visibility: hidden;
    }
  }
  
  @keyframes slider-three {
    0% {
      visibility: hidden;
    }
  
    25% {
      visibility: hidden;
    }
  
    50% {
      visibility: hidden;
    }
  
    75% {
      visibility: visible;
    }
  
    100% {
      visibility: hidden;
    }
  }
  
  @keyframes slider-four {
    0% {
      visibility: hidden;
    }
  
    25% {
      visibility: hidden;
    }
  
    50% {
      visibility: hidden;
    }
  
    75% {
      visibility: hidden;
    }
  
    100% {
      visibility: visible;
    }
  }
  
  /*
  https://images.pexels.com/photos/248747/pexels-photo-248747.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260
  
  
  https://images.unsplash.com/photo-1587726763541-d8e58f4fb2fb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80
  
  https://images.pexels.com/photos/1413412/pexels-photo-1413412.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260
  
  https://images.pexels.com/photos/2240766/pexels-photo-2240766.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500 */